var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col md:w-1/2 w-full mb-base" },
      [
        _c(
          "vx-card",
          { attrs: { title: "Horizontal Form", "code-toggler": "" } },
          [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("First Name")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    model: {
                      value: _vm.input1,
                      callback: function ($$v) {
                        _vm.input1 = $$v
                      },
                      expression: "input1",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("Email")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "email" },
                    model: {
                      value: _vm.input2,
                      callback: function ($$v) {
                        _vm.input2 = $$v
                      },
                      expression: "input2",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("Mobile")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    model: {
                      value: _vm.input3,
                      callback: function ($$v) {
                        _vm.input3 = $$v
                      },
                      expression: "input3",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("Password")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "password" },
                    model: {
                      value: _vm.input4,
                      callback: function ($$v) {
                        _vm.input4 = $$v
                      },
                      expression: "input4",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full ml-auto" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "inline-flex",
                      model: {
                        value: _vm.check1,
                        callback: function ($$v) {
                          _vm.check1 = $$v
                        },
                        expression: "check1",
                      },
                    },
                    [_vm._v("Remember Me")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full ml-auto" },
                [
                  _c("vs-button", { staticClass: "mr-3 mb-2" }, [
                    _vm._v("Submit"),
                  ]),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-2",
                      attrs: { color: "warning", type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.input1 =
                            _vm.input2 =
                            _vm.input3 =
                            _vm.input4 =
                            _vm.input4 =
                              ""
                          _vm.check1 = false
                        },
                      },
                    },
                    [_vm._v("Reset")]
                  ),
                ],
                1
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<template>\n    <div class="vx-row mb-6">\n      <div class="vx-col sm:w-1/3 w-full">\n        <span>First Name</span>\n      </div>\n      <div class="vx-col sm:w-2/3 w-full">\n        <vs-input class="w-full" v-model="input1" />\n      </div>\n    </div>\n    <div class="vx-row mb-6">\n      <div class="vx-col sm:w-1/3 w-full">\n        <span>Email</span>\n      </div>\n      <div class="vx-col sm:w-2/3 w-full">\n        <vs-input class="w-full" type="email" v-model="input2" />\n      </div>\n    </div>\n    <div class="vx-row mb-6">\n      <div class="vx-col sm:w-1/3 w-full">\n        <span>Mobile</span>\n      </div>\n      <div class="vx-col sm:w-2/3 w-full">\n        <vs-input class="w-full" v-model="input3" />\n      </div>\n    </div>\n    <div class="vx-row mb-6">\n      <div class="vx-col sm:w-1/3 w-full">\n        <span>Password</span>\n      </div>\n      <div class="vx-col sm:w-2/3 w-full">\n        <vs-input class="w-full" type="password" v-model="input4" />\n      </div>\n    </div>\n    <div class="vx-row mb-6">\n      <div class="vx-col sm:w-2/3 w-full ml-auto">\n        <vs-checkbox class="inline-flex" v-model="check1">Remember Me</vs-checkbox>\n      </div>\n    </div>\n    <div class="vx-row">\n      <div class="vx-col sm:w-2/3 w-full ml-auto">\n        <vs-button class="mr-3 mb-2">Submit</vs-button>\n        <vs-button color="warning" type="border" class="mb-2" @click="input1 = input2 = input3 = input4 = input4 = \'\'; check1 = false;">Reset</vs-button>\n      </div>\n    </div>\n</template>\n        '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col md:w-1/2 w-full mb-base" },
      [
        _c(
          "vx-card",
          { attrs: { title: "Horizontal Form", "code-toggler": "" } },
          [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("First Name")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-user",
                      "icon-no-border": "",
                    },
                    model: {
                      value: _vm.input5,
                      callback: function ($$v) {
                        _vm.input5 = $$v
                      },
                      expression: "input5",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("Email")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "email",
                      "icon-pack": "feather",
                      icon: "icon-mail",
                      "icon-no-border": "",
                    },
                    model: {
                      value: _vm.input6,
                      callback: function ($$v) {
                        _vm.input6 = $$v
                      },
                      expression: "input6",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("Mobile")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-smartphone",
                      "icon-no-border": "",
                    },
                    model: {
                      value: _vm.input7,
                      callback: function ($$v) {
                        _vm.input7 = $$v
                      },
                      expression: "input7",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                _c("span", [_vm._v("Password")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "password",
                      "icon-pack": "feather",
                      icon: "icon-lock",
                      "icon-no-border": "",
                    },
                    model: {
                      value: _vm.input8,
                      callback: function ($$v) {
                        _vm.input8 = $$v
                      },
                      expression: "input8",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full ml-auto" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "inline-flex",
                      model: {
                        value: _vm.check2,
                        callback: function ($$v) {
                          _vm.check2 = $$v
                        },
                        expression: "check2",
                      },
                    },
                    [_vm._v("Remember Me")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/3 w-full ml-auto" },
                [
                  _c("vs-button", { staticClass: "mr-3 mb-2" }, [
                    _vm._v("Submit"),
                  ]),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-2",
                      attrs: { color: "warning", type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.input5 = _vm.input6 = _vm.input7 = _vm.input8 = ""
                          _vm.check2 = false
                        },
                      },
                    },
                    [_vm._v("Reset")]
                  ),
                ],
                1
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<template>\n  <div class="vx-row mb-6">\n    <div class="vx-col sm:w-1/3 w-full">\n      <span>First Name</span>\n    </div>\n    <div class="vx-col sm:w-2/3 w-full">\n      <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border v-model="input5" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col sm:w-1/3 w-full">\n      <span>Email</span>\n    </div>\n    <div class="vx-col sm:w-2/3 w-full">\n      <vs-input type="email" class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border v-model="input6" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col sm:w-1/3 w-full">\n      <span>Mobile</span>\n    </div>\n    <div class="vx-col sm:w-2/3 w-full">\n      <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border v-model="input7" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col sm:w-1/3 w-full">\n      <span>Password</span>\n    </div>\n    <div class="vx-col sm:w-2/3 w-full">\n      <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" icon-no-border v-model="input8" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col sm:w-2/3 w-full ml-auto">\n      <vs-checkbox class="inline-flex" v-model="check2">Remember Me</vs-checkbox>\n    </div>\n  </div>\n  <div class="vx-row">\n    <div class="vx-col sm:w-2/3 w-full ml-auto">\n      <vs-button class="mr-3 mb-2">Submit</vs-button>\n      <vs-button color="warning" type="border" class="mb-2" @click="input5 = input6 = input7 = input8 = \'\'; check2 = false;">Reset</vs-button>\n    </div>\n  </div>\n</template>\n        '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col md:w-1/2 w-full mb-base" },
      [
        _c(
          "vx-card",
          { attrs: { title: "Vertical Form", "code-toggler": "" } },
          [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: "First Name" },
                    model: {
                      value: _vm.input9,
                      callback: function ($$v) {
                        _vm.input9 = $$v
                      },
                      expression: "input9",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "email", label: "Email" },
                    model: {
                      value: _vm.input10,
                      callback: function ($$v) {
                        _vm.input10 = $$v
                      },
                      expression: "input10",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: "Mobile" },
                    model: {
                      value: _vm.input11,
                      callback: function ($$v) {
                        _vm.input11 = $$v
                      },
                      expression: "input11",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "password", label: "Password" },
                    model: {
                      value: _vm.input12,
                      callback: function ($$v) {
                        _vm.input12 = $$v
                      },
                      expression: "input12",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "inline-flex",
                      model: {
                        value: _vm.check3,
                        callback: function ($$v) {
                          _vm.check3 = $$v
                        },
                        expression: "check3",
                      },
                    },
                    [_vm._v("Remember Me")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-button", { staticClass: "mr-3 mb-2" }, [
                    _vm._v("Submit"),
                  ]),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-2",
                      attrs: { color: "warning", type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.input9 =
                            _vm.input10 =
                            _vm.input11 =
                            _vm.input12 =
                              ""
                          _vm.check3 = false
                        },
                      },
                    },
                    [_vm._v("Reset")]
                  ),
                ],
                1
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<template>\n  <div class="vx-row mb-6">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" label="First Name" v-model="input9" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" type="email" label="Email" v-model="input10" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" label="Mobile" v-model="input11" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" type="password" label="Password" v-model="input12" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col w-full">\n      <vs-checkbox class="inline-flex" v-model="check3">Remember Me</vs-checkbox>\n    </div>\n  </div>\n  <div class="vx-row">\n    <div class="vx-col w-full">\n      <vs-button class="mr-3 mb-2">Submit</vs-button>\n      <vs-button color="warning" type="border" class="mb-2" @click="input9 = input10 = input11 = input12 = \'\'; check3 = false;">Reset</vs-button>\n    </div>\n  </div>\n</template>\n        '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col md:w-1/2 w-full mb-base" },
      [
        _c(
          "vx-card",
          { attrs: { title: "Vertical Form", "code-toggler": "" } },
          [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-user",
                      "icon-no-border": "",
                      label: "First Name",
                    },
                    model: {
                      value: _vm.input13,
                      callback: function ($$v) {
                        _vm.input13 = $$v
                      },
                      expression: "input13",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "email",
                      "icon-pack": "feather",
                      icon: "icon-mail",
                      "icon-no-border": "",
                      label: "Email",
                    },
                    model: {
                      value: _vm.input14,
                      callback: function ($$v) {
                        _vm.input14 = $$v
                      },
                      expression: "input14",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-smartphone",
                      "icon-no-border": "",
                      label: "Mobile",
                    },
                    model: {
                      value: _vm.input15,
                      callback: function ($$v) {
                        _vm.input15 = $$v
                      },
                      expression: "input15",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "password",
                      "icon-pack": "feather",
                      icon: "icon-lock",
                      "icon-no-border": "",
                      label: "Password",
                    },
                    model: {
                      value: _vm.input16,
                      callback: function ($$v) {
                        _vm.input16 = $$v
                      },
                      expression: "input16",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "inline-flex",
                      model: {
                        value: _vm.check4,
                        callback: function ($$v) {
                          _vm.check4 = $$v
                        },
                        expression: "check4",
                      },
                    },
                    [_vm._v("Remember Me")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-button", { staticClass: "mr-3 mb-2" }, [
                    _vm._v("Submit"),
                  ]),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-2",
                      attrs: { color: "warning", type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.input13 =
                            _vm.input14 =
                            _vm.input15 =
                            _vm.input16 =
                              ""
                          _vm.check4 = false
                        },
                      },
                    },
                    [_vm._v("Cancle")]
                  ),
                ],
                1
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<template>\n    <div class="vx-row mb-6">\n      <div class="vx-col w-full">\n        <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="First Name" v-model="input13" />\n      </div>\n    </div>\n    <div class="vx-row mb-6">\n      <div class="vx-col w-full">\n        <vs-input type="email" class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border label="Email" v-model="input14" />\n      </div>\n    </div>\n    <div class="vx-row mb-6">\n      <div class="vx-col w-full">\n        <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border label="Mobile" v-model="input15" />\n      </div>\n    </div>\n    <div class="vx-row mb-6">\n      <div class="vx-col w-full">\n        <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" icon-no-border label="Password" v-model="input16" />\n      </div>\n    </div>\n    <div class="vx-row mb-6">\n      <div class="vx-col w-full">\n        <vs-checkbox class="inline-flex" v-model="check4">Remember Me</vs-checkbox>\n      </div>\n    </div>\n    <div class="vx-row">\n      <div class="vx-col w-full">\n        <vs-button class="mr-3 mb-2">Submit</vs-button>\n        <vs-button color="warning" type="border" class="mb-2" @click="input13 = input14 = input15 = input16 = \'\'; check4 = false;">Cancle</vs-button>\n      </div>\n    </div>\n</template>\n        '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col md:w-1/2 w-full mb-base" },
      [
        _c(
          "vx-card",
          {
            attrs: { title: "Form With Label Placeholder", "code-toggler": "" },
          },
          [
            _c("div", { staticClass: "vx-row mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { "label-placeholder": "First Name" },
                    model: {
                      value: _vm.input17,
                      callback: function ($$v) {
                        _vm.input17 = $$v
                      },
                      expression: "input17",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "email", "label-placeholder": "Email" },
                    model: {
                      value: _vm.input18,
                      callback: function ($$v) {
                        _vm.input18 = $$v
                      },
                      expression: "input18",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { "label-placeholder": "Mobile" },
                    model: {
                      value: _vm.input19,
                      callback: function ($$v) {
                        _vm.input19 = $$v
                      },
                      expression: "input19",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "password",
                      "label-placeholder": "Password",
                    },
                    model: {
                      value: _vm.input20,
                      callback: function ($$v) {
                        _vm.input20 = $$v
                      },
                      expression: "input20",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "inline-flex",
                      model: {
                        value: _vm.check5,
                        callback: function ($$v) {
                          _vm.check5 = $$v
                        },
                        expression: "check5",
                      },
                    },
                    [_vm._v("Remember Me")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-button", { staticClass: "mr-3 mb-2" }, [
                    _vm._v("Submit"),
                  ]),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-2",
                      attrs: { color: "warning", type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.input17 =
                            _vm.input18 =
                            _vm.input19 =
                            _vm.input20 =
                              ""
                          _vm.check5 = false
                        },
                      },
                    },
                    [_vm._v("Cancle")]
                  ),
                ],
                1
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<template>\n  <div class="vx-row mb-2">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" label-placeholder="First Name" v-model="input17" />\n    </div>\n  </div>\n  <div class="vx-row mb-2">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" type="email" label-placeholder="Email" v-model="input18" />\n    </div>\n  </div>\n  <div class="vx-row mb-2">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" label-placeholder="Mobile" v-model="input19" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" type="password" label-placeholder="Password" v-model="input20" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col w-full">\n      <vs-checkbox class="inline-flex" v-model="check5">Remember Me</vs-checkbox>\n    </div>\n  </div>\n  <div class="vx-row">\n    <div class="vx-col w-full">\n      <vs-button class="mr-3 mb-2">Submit</vs-button>\n      <vs-button color="warning" type="border" class="mb-2" @click="input17 = input18 = input19 = input20 = \'\'; check5 = false;">Cancle</vs-button>\n    </div>\n  </div>\n</template>\n        '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col md:w-1/2 w-full mb-base" },
      [
        _c(
          "vx-card",
          {
            attrs: { title: "Form With Label Placeholder", "code-toggler": "" },
          },
          [
            _c("div", { staticClass: "vx-row mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-user",
                      "icon-no-border": "",
                      "label-placeholder": "First Name",
                    },
                    model: {
                      value: _vm.input21,
                      callback: function ($$v) {
                        _vm.input21 = $$v
                      },
                      expression: "input21",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "email",
                      "icon-pack": "feather",
                      icon: "icon-mail",
                      "icon-no-border": "",
                      "label-placeholder": "Email",
                    },
                    model: {
                      value: _vm.input22,
                      callback: function ($$v) {
                        _vm.input22 = $$v
                      },
                      expression: "input22",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-smartphone",
                      "icon-no-border": "",
                      "label-placeholder": "Mobile",
                    },
                    model: {
                      value: _vm.input23,
                      callback: function ($$v) {
                        _vm.input23 = $$v
                      },
                      expression: "input23",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "password",
                      "icon-pack": "feather",
                      icon: "icon-lock",
                      "icon-no-border": "",
                      "label-placeholder": "Password",
                    },
                    model: {
                      value: _vm.input24,
                      callback: function ($$v) {
                        _vm.input24 = $$v
                      },
                      expression: "input24",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "inline-flex",
                      model: {
                        value: _vm.check6,
                        callback: function ($$v) {
                          _vm.check6 = $$v
                        },
                        expression: "check6",
                      },
                    },
                    [_vm._v("Remember Me")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-button", { staticClass: "mr-3 mb-2" }, [
                    _vm._v("Submit"),
                  ]),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-2",
                      attrs: { color: "warning", type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.input21 =
                            _vm.input22 =
                            _vm.input23 =
                            _vm.input24 =
                              ""
                          _vm.check6 = false
                        },
                      },
                    },
                    [_vm._v("Reset")]
                  ),
                ],
                1
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<template>\n  <div class="vx-row mb-2">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label-placeholder="First Name" v-model="input21" />\n    </div>\n  </div>\n  <div class="vx-row mb-2">\n    <div class="vx-col w-full">\n      <vs-input type="email" class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border label-placeholder="Email" v-model="input22" />\n    </div>\n  </div>\n  <div class="vx-row mb-2">\n    <div class="vx-col w-full">\n      <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border label-placeholder="Mobile" v-model="input23" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col w-full">\n      <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" icon-no-border label-placeholder="Password" v-model="input24" />\n    </div>\n  </div>\n  <div class="vx-row mb-6">\n    <div class="vx-col w-full">\n      <vs-checkbox class="inline-flex" v-model="check6">Remember Me</vs-checkbox>\n    </div>\n  </div>\n  <div class="vx-row">\n    <div class="vx-col w-full">\n      <vs-button class="mr-3 mb-2">Submit</vs-button>\n      <vs-button color="warning" type="border" class="mb-2" @click="input21 = input22 = input23 = input24 = \'\'; check6 = false;">Reset</vs-button>\n    </div>\n  </div>\n</template>\n        '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col w-full" },
      [
        _c(
          "vx-card",
          { attrs: { title: "Multiple Columns", "code-toggler": "" } },
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/2 w-full mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { "label-placeholder": "First Name" },
                    model: {
                      value: _vm.input25,
                      callback: function ($$v) {
                        _vm.input25 = $$v
                      },
                      expression: "input25",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/2 w-full mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { "label-placeholder": "Last Name" },
                    model: {
                      value: _vm.input26,
                      callback: function ($$v) {
                        _vm.input26 = $$v
                      },
                      expression: "input26",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/2 w-full mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { "label-placeholder": "City" },
                    model: {
                      value: _vm.input27,
                      callback: function ($$v) {
                        _vm.input27 = $$v
                      },
                      expression: "input27",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/2 w-full mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { "label-placeholder": "Country" },
                    model: {
                      value: _vm.input28,
                      callback: function ($$v) {
                        _vm.input28 = $$v
                      },
                      expression: "input28",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/2 w-full mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { "label-placeholder": "Company" },
                    model: {
                      value: _vm.input29,
                      callback: function ($$v) {
                        _vm.input29 = $$v
                      },
                      expression: "input29",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/2 w-full mb-6" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "email", "label-placeholder": "Email" },
                    model: {
                      value: _vm.input30,
                      callback: function ($$v) {
                        _vm.input30 = $$v
                      },
                      expression: "input30",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full mb-6" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "inline-flex",
                      model: {
                        value: _vm.check7,
                        callback: function ($$v) {
                          _vm.check7 = $$v
                        },
                        expression: "check7",
                      },
                    },
                    [_vm._v("Remember Me")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-button", { staticClass: "mr-3 mb-2" }, [
                    _vm._v("Submit"),
                  ]),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-2",
                      attrs: { color: "warning", type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.input25 =
                            _vm.input26 =
                            _vm.input27 =
                            _vm.input28 =
                            _vm.input29 =
                            _vm.input30 =
                              ""
                          _vm.check7 = false
                        },
                      },
                    },
                    [_vm._v("Reset")]
                  ),
                ],
                1
              ),
            ]),
            _c("template", { slot: "codeContainer" }, [
              _vm._v(
                '\n<template>\n  <div class="vx-row">\n    <div class="vx-col sm:w-1/2 w-full mb-2">\n      <vs-input class="w-full" label-placeholder="First Name" v-model="input25" />\n    </div>\n    <div class="vx-col sm:w-1/2 w-full mb-2">\n      <vs-input class="w-full" label-placeholder="Last Name" v-model="input26" />\n    </div>\n  </div>\n  <div class="vx-row">\n    <div class="vx-col sm:w-1/2 w-full mb-2">\n      <vs-input class="w-full" label-placeholder="City" v-model="input27" />\n    </div>\n    <div class="vx-col sm:w-1/2 w-full mb-2">\n      <vs-input class="w-full" label-placeholder="Country" v-model="input28" />\n    </div>\n  </div>\n  <div class="vx-row">\n    <div class="vx-col sm:w-1/2 w-full mb-2">\n      <vs-input class="w-full" label-placeholder="Company" v-model="input29" />\n    </div>\n    <div class="vx-col sm:w-1/2 w-full mb-6">\n      <vs-input type="email" class="w-full" label-placeholder="Email" v-model="input30" />\n    </div>\n  </div>\n  <div class="vx-row">\n    <div class="vx-col w-full mb-6">\n      <vs-checkbox class="inline-flex" v-model="check7">Remember Me</vs-checkbox>\n    </div>\n  </div>\n  <div class="vx-row">\n    <div class="vx-col w-full">\n      <vs-button class="mr-3 mb-2">Submit</vs-button>\n      <vs-button color="warning" type="border" class="mb-2" @click="input25 = input26 = input27 = input28 = input29 = input30 = \'\'; check7 = false;">Reset</vs-button>\n    </div>\n  </div>\n</template>\n        '
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }